import { motion } from "framer-motion"
import { graphql, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import ReactPannellum from "react-pannellum"
import { useKey } from "react-use"
import Btn from "../components/Btn"
import { useSupportsWebP } from "../utils/helpers"

import "./panoramic-image.scss"

const PannellumModal = ({ data }) => {
  const { panoramicImage } = data.contentful360Image

  const supportsWebP = useSupportsWebP()

  const [imageSource, setImageSource] = useState(null)

  useEffect(() => {
    if (supportsWebP === null) return
    const imageSource = supportsWebP
      ? panoramicImage.localFile.full.fixed.srcWebp
      : panoramicImage.localFile.full.fixed.src

    setImageSource(imageSource)
  }, [supportsWebP])

  //   const imageSource = panoramicImage.localFile.full.fixed.srcWebp

  console.log(imageSource)

  useKey("Escape", () => navigate(-1))

  return (
    <div className="pannellum-container">
      {imageSource && (
        <>
          <ReactPannellum
            id="1"
            sceneId="firstScene"
            imageSource={imageSource}
            style={{ width: "100%", height: "100%" }}
            config={{ autoLoad: true }}
          />
          <Btn
            size="sm"
            className="pannellum-container__close-btn"
            // onClick={() => closePanoramicImage()}
            onClick={() => navigate(-1)}
          >
            Exit panorama
          </Btn>
        </>
      )}
    </div>
  )
}

export default PannellumModal

export const query = graphql`
  query($id: String) {
    contentful360Image(id: { eq: $id }) {
      id
      panoramicImage {
        localFile {
          full: childImageSharp {
            fixed(width: 8192, quality: 95) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  }
`
